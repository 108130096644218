<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8">
    <div class="col-4">
      <!--
          gradientToColors: ["#f94144", "#f8961e", "#f9c74f", "#577590", "#277da1", "#43aa8b", "#90be6d"], -->
      <WidgetPercent
          widget-classes="card-xl-stretch mb-5 mb-xl-12"
          chart-color="#018001"
          chart-height="400"
          widgetTitle="Maintenance schedule compliance"
          widgetSubtitle="All assets"
          note=""
          chartValue="90"
          fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-4">
      <WidgetPercent
          widget-classes="card-xl-stretch mb-5 mb-xl-12"
          chart-color="#8ac926"
          chart-height="400"
          widgetTitle="Planned maintenance percentage"
          widgetSubtitle="All assets"
          note=""
          chartValue="85"
          fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-4">
      <WidgetPercent
          widget-classes="card-xl-stretch mb-5 mb-xl-12"
          chart-color="#ff9f1c"
          chart-height="400"
          widgetTitle="Equipment health"
          widgetSubtitle="All assets"
          note=""
          chartValue="50"
          fontSize="35"
      ></WidgetPercent>
    </div>
  </div>
  <div class="row gy-5 g-xl-8">
    <div class="col-2">
      <WidgetNumber
          widget-classes="card-xl-stretch-50 mb-5 mb-xl-8"
          chart-color="danger"
          chart-height="100"
          widgetTitle="Low stock items"
          widgetSubtitle="All assets"
          note=""
          chartValue="598"
          fontSize="60"
      ></WidgetNumber>
      <WidgetNumber
          widget-classes="card-xl-stretch-50 mb-xl-8"
          chart-color="warning"
          chart-height="100"
          widgetTitle="Excess stock items"
          widgetSubtitle="All assets"
          note=""
          chartValue="19"
          fontSize="30"
      ></WidgetNumber>
    </div>
    <div class="col-4">
      <WidgetPercent
          widget-classes="card-xl-stretch mb-5 mb-xl-12"
          chart-color="#d41532"
          chart-height="400"
          widgetTitle="Stock compliance"
          widgetSubtitle="All assets"
          note=""
          chartValue="3"
          fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-2">
      <WidgetNumber
          widget-classes="card-xl-stretch-50 mb-5 mb-xl-8"
          chart-color="warning"
          chart-height="100"
          widgetTitle="POs awaiting approval"
          widgetSubtitle="All assets"
          note=""
          chartValue="0"
          fontSize="50"
      ></WidgetNumber>
      <WidgetNumber
          widget-classes="card-xl-stretch-50 mb-xl-8"
          chart-color="info"
          chart-height="100"
          widgetTitle="PO items awaiting arrival"
          widgetSubtitle="All assets"
          note=""
          chartValue="7"
          fontSize="50"
      ></WidgetNumber>
    </div>
    <div class="col-4">
      <!--
          gradientToColors: ["#f94144", "#f8961e", "#f9c74f", "#577590", "#277da1", "#43aa8b", "#90be6d"], -->
      <WidgetPercent
          widget-classes="card-xl-stretch mb-5 mb-xl-12"
          chart-color="#3dbf3d"
          chart-height="400"
          widgetTitle="Procurement schedule compliance"
          widgetSubtitle="All assets"
          note=""
          chartValue="70"
          fontSize="35"
      ></WidgetPercent>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import WidgetPercent from "@/components/widgets/mixed/WidgetPercent.vue";
import WidgetNumber from "@/components/widgets/mixed/WidgetNumber.vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard2",
  components: {
    WidgetPercent,
    WidgetNumber
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      setCurrentPageBreadcrumbs("Dashboard", []);
      setCurrentPageToolbarType("dashboard2");
    });
  }
});
</script>
